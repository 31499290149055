import { Link } from "@quintype/components";
import React from "react";
import "./hamburger-menu-list.m.css";

function HamburgerMenuList({ menus, isSingleMenu }) {
  return (
    <div styleName="wrapper">
      <div styleName="triangle"></div>
      {menus.map((menu, idx) => (
        <div styleName={`menu ${isSingleMenu ? "single-menu" : ""}`} key={idx}>
          <Link href={menu.url}>{menu.title}</Link>
        </div>
      ))}
    </div>
  );
}

export default HamburgerMenuList;
