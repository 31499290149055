import React from "react";

import "./live-icon.m.css";

const LiveIcon = ({ width = 10, height = 10, blinkingColor = "red" }) => (
  <div styleName="live-icon-container">
    <svg
      className="live-icon"
      styleName="live-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 250 250"
    >
      <defs>
        <clipPath id="clip-live_icon">
          <rect width="250" height="250" />
        </clipPath>
      </defs>
      <g id="live_icon" clipPath="url(#clip-live_icon)">
        <rect width="250" height="250" fill="#fff" fillOpacity="0" />
        <g
          id="Ellipse_35"
          data-name="Ellipse 35"
          transform="translate(6 6)"
          fill="none"
          stroke={blinkingColor}
          strokeWidth="15"
        >
          <circle cx="119" cy="119" r="119" stroke="none" />
          <circle cx="119" cy="119" r="111.5" fill="none" />
        </g>
        <circle
          styleName="animation"
          id="Ellipse_36"
          data-name="Ellipse 36"
          cx="69"
          cy="69"
          r="69"
          transform="translate(56 56)"
          fill={blinkingColor}
        />
      </g>
    </svg>
  </div>
);

export default LiveIcon;
