import { PAGE_TYPE } from "./constants";
import { pickComponentHelper } from "@quintype/framework/server/pick-component-helper";

const { pickComponent, getChunkName } = pickComponentHelper(
  {
    [PAGE_TYPE.HOME_PAGE]: { chunk: "home", component: "HomePage" },
    [PAGE_TYPE.SECTION_PAGE]: { chunk: "list", component: "SectionPage" },
    [PAGE_TYPE.SUB_SECTION_PAGE]: { chunk: "list", component: "SubSectionPage" },
    [PAGE_TYPE.CARTOON_PAGE]: { chunk: "list", component: "CartoonPage" },
    [PAGE_TYPE.COLLECTION_PAGE]: { chunk: "list", component: "CollectionPage" },
    [PAGE_TYPE.TAG_PAGE]: { chunk: "list", component: "TagPage" },
    [PAGE_TYPE.SEARCH_PAGE]: { chunk: "list", component: "SearchPage" },
    [PAGE_TYPE.STORY_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.CATALOG_PAGE]: { chunk: "list", component: "CatalogPage" },
    [PAGE_TYPE.STORY_PREVIEW]: { chunk: "story", component: "StoryPagePreview" },
    [PAGE_TYPE.STORY_PUBLIC_PREVIEW_PAGE]: { chunk: "story", component: "StoryPage" },
    [PAGE_TYPE.AUTHOR_PAGE]: { chunk: "list", component: "AuthorPage" },
    [PAGE_TYPE.AUTHORS_PAGE]: { chunk: "list", component: "AuthorListingPage" },
    [PAGE_TYPE.HOROSCOPE_PAGE]: { chunk: "list", component: "HoroscopeListing" },
    [PAGE_TYPE.INSTAGRAM_PAGE]: { chunk: "list", component: "InstagramSection" },
    [PAGE_TYPE.NEWS_SHOTS_PAGE]: { chunk: "list", component: "NewsShotsPage" },
    [PAGE_TYPE.BRAND_SPOT_PAGE]: { chunk: "list", component: "BrandSpotPage" },
    [PAGE_TYPE.NEWSLETTERS_PAGE]: { chunk: "list", component: "NewsLetterPage" },
    [PAGE_TYPE.STUDENTS_CONTEST]: { chunk: "list", component: "StudentContestPage" },
    [PAGE_TYPE.NEWSLETTERS_UNSUBSCRIBE_PAGE]: { chunk: "list", component: "NewsLetterUnsubscribePage" },
    [PAGE_TYPE.HOME_PREVIEW]: { chunk: "home", component: "HomePagePreview" },
    default: { chunk: "list", component: "NotFoundPage" }
  },
  {
    home: () => import(/* webpackChunkName: "home" */ "./component-bundles/home.js"),
    list: () => import(/* webpackChunkName: "list" */ "./component-bundles/list.js"),
    story: () => import(/* webpackChunkName: "story" */ "./component-bundles/story.js")
  }
);

export { pickComponent, getChunkName };
