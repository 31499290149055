import {
  OPEN_HAMBURGER_MENU,
  OPEN_SEARCHBAR,
  UPDATE_USER,
  IS_OPEN_LOGIN_FORM,
  ISLOGIN,
  UPDATE_COMMENTS_TOKEN,
  NOTIFICATION_DATA,
  OPEN_MODAL,
  ISLOADINGINFO,
  IS_NEW_NOTIFICATION
} from "./actions";

const storyReducerState = {
  moreStories: [],
  latestTenStories: [],
  latestSectionStories: [],
  makeSecondaryApiCalls: false,
  delay: 500,
  isStickyBannerVisible: false,
  storyTemplate: ""
};

const userState = {
  user: {},
  commentsToken: ""
};

const storyReducer = (state = storyReducerState, action) => {
  switch (action.type) {
    case "MORE_STORIES":
      return {
        ...state,
        moreStories: action.payload
      };
    case "SET_LATEST_TEN_STORIES":
      return {
        ...state,
        latestTenStories: action.payload
      };
    case "SET_LATEST_SECTION_STORIES":
      return {
        ...state,
        latestSectionStories: action.payload
      };
    case "SET_MAKE_SECONDARY_API_CALLS_TRIGGERED":
      return {
        ...state,
        makeSecondaryApiCalls: action.makeSecondaryApiCalls,
        delay: action.delay
      };
    case "SET_IS_STICKY_BANNER_VISIBLE":
      return {
        ...state,
        isStickyBannerVisible: action.payload
      };
    case "STORY_TEMPLATE":
      return {
        ...state,
        storyTemplate: action.payload
      };
    default:
      return state;
  }
};

function hamburgerMenuReducer(state = false, action) {
  switch (action.type) {
    case OPEN_HAMBURGER_MENU:
      return action.isHamburgerMenuOpen;
    default:
      return state;
  }
}

function searchBarReducer(state = false, action) {
  switch (action.type) {
    case OPEN_SEARCHBAR:
      return action.isSearchBarOpen;
    default:
      return state;
  }
}

function userReducer(state = userState, action) {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        user: action.payload
      };
    case UPDATE_COMMENTS_TOKEN:
      return {
        ...state,
        commentsToken: action.payload
      };
    default:
      return state;
  }
}

function isLoginReducer(state = false, action) {
  switch (action.type) {
    case ISLOGIN:
      return action.payload;

    default:
      return state;
  }
}

function loginReducer(state = false, action) {
  switch (action.type) {
    case IS_OPEN_LOGIN_FORM:
      return action.payload;
    default:
      return state;
  }
}

function notificationDataReducer(state = [], action) {
  switch (action.type) {
    case NOTIFICATION_DATA:
      return action.payload;
    default:
      return state;
  }
}
function modalToggle(state = false, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return action.payload;
    default:
      return state;
  }
}
function isLoadingReducer(state = false, action) {
  switch (action.type) {
    case ISLOADINGINFO:
      return action.payload;
    default:
      return state;
  }
}
function isNewNotificationReducer(state = false, action) {
  switch (action.type) {
    case IS_NEW_NOTIFICATION:
      return action.payload;
    default:
      return state;
  }
}

export const REDUCERS = {
  isHamburgerMenuOpen: hamburgerMenuReducer,
  isSearchBarOpen: searchBarReducer,
  user: userReducer,
  isLoginOpen: loginReducer,
  storyReducer: storyReducer,
  isLogin: isLoginReducer,
  notificationList: notificationDataReducer,
  isModalOpen: modalToggle,
  isLoading: isLoadingReducer,
  isNewNotification: isNewNotificationReducer
};
