import { Link } from "@quintype/components";
import React from "react";
import "./header-newsletter.m.css";
import { SvgIconHandler } from "../svg-icon-hadler";

function HeaderNewsletter() {
  return (
    <div styleName="wrapper">
      <Link target="_blank" href="https://www.deccanherald.com/newsletters" styleName="newsletter">
        Newsletters <SvgIconHandler type="newsletter" width="13.33px" height="13.83px" />
      </Link>
    </div>
  );
}

export default HeaderNewsletter;
