import React from "react";

import "./separator.m.css";

const Separator = () => {
  return (
    <div styleName="separator-wrapper" className="separator-wrapper">
      <div styleName="line-separator"></div>
      <div styleName="line-separator"></div>
      <div styleName="line-separator"></div>
    </div>
  );
};

export default Separator;
