import React, { useEffect } from "react";
import "./modal.m.css";

function Modal({ children, isOpen, onClose }) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);

  const closeModal = () => {
    onClose();
  };

  if (isOpen) {
    return (
      <div className="modal-container" onClick={closeModal}>
        <div className="modal-inner" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    );
  }
  return null;
}

export default Modal;
