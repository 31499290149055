import { Link } from "@quintype/components";
import React from "react";
import "./read-ePaper-button.m.css";

function ReadEPaperButton() {
  return (
    <div styleName="wrapper">
      <Link href="https://epaper.deccanherald.com/" styleName="e-paper-btn" target="_blank">
        Read ePaper
      </Link>
    </div>
  );
}

export default ReadEPaperButton;
