import React from "react";
import { string } from "prop-types";

function HambugerMenuIcon({ color = "#000" }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Assets" transform="translate(-40.000000, -88.000000)">
          <g id="Hamburger" transform="translate(40.000000, 88.000000)">
            <polygon id="Path-Copy" points="0 0 24 0 24 24 0 24"></polygon>
            <g
              id="Group-5"
              transform="translate(4.000000, 6.000000)"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            >
              <line x1="0" y1="5.86666667" x2="16" y2="5.86666667" id="Path"></line>
              <line x1="0" y1="0.451282051" x2="16" y2="0.451282051" id="Path"></line>
              <line x1="0" y1="11.2820513" x2="16" y2="11.2820513" id="Path"></line>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

HambugerMenuIcon.propTypes = {
  color: string
};

export default HambugerMenuIcon;
