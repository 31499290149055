import React from "react";
import { Link } from "@quintype/components";

import "./footer-collection.m.css";

const FooterCollection = ({ collection }) => {
  if (!collection.items) return null;

  const collections = collection?.items?.filter((i) => i.type === "collection");

  return (
    <div styleName="footer-collections">
      {collections.map((collection, index) => {
        const stories = collection?.items?.filter((i) => i.type === "story").map((i) => i.story);

        return (
          <div key={index} styleName="collection-item">
            <div styleName="collection-title">
              <Link href={`/${collection.slug}`} styleName="footer-collection-href">
                {collection.metadata?.["display-name"] || collection?.name}
              </Link>
            </div>

            <div>
              <ul>
                {stories?.splice(0, 4).map((story, index) => (
                  <li key={index} styleName="footer-story-name">
                    <Link href={`/${story.slug}`} key={index}>
                      <span styleName="dot"></span>
                      <span className="line-clamp line-clamp-2" styleName="collection-data" key={index}>
                        {story.headline}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FooterCollection;
