import { Link } from "@quintype/components";
import React from "react";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import "./follow-us-social-media.m.css";
const SOCIAL_MEDIA_CONTENT = [
  { link: "https://www.whatsapp.com/channel/0029Va4ifN6AYlULZASc7V3S", icon: "whatsapp_black" },
  { link: "https://www.facebook.com/deccanherald/", icon: "facebook_black" },
  { link: "https://twitter.com/deccanherald", icon: "twitter_black" },
  { link: "https://www.instagram.com/deccanherald/", icon: "instagram_black" },
  { link: "https://www.youtube.com/channel/UCHDXXHPrz-1GVbK_qk82hBQ", icon: "youtube_black" },
  { link: "https://t.me/DeccanHeraldNews", icon: "telegram_black" }
];

function FollowUsSocialMedia() {
  return (
    <div styleName="wrapper">
      <span>
        <h2>Follow Us</h2>
      </span>
      <div styleName="subscribe-social-icons">
        {SOCIAL_MEDIA_CONTENT?.map((item, index) => (
          <Link href={item.link} key={index} target="_blank">
            <SvgIconHandler type={item.icon} height="24px" width="25px" />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default FollowUsSocialMedia;
