/* eslint-disable max-len */

import React from "react";

export default function HamburgerDHLogo({ width = "60", height = "34" }) {
  return (
    <svg width={width} height={height} viewBox="0 0 60 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.4268 9.43062C24.1468 -0.267822 15.5179 0.0408238 15.5179 0.0408238H0V1.28051C0 1.28051 3.09564 1.4659 3.5744 3.44511V29.8623C3.09564 31.8406 0 32.0248 0 32.0248V33.2665H13.9197C20.1945 33.1056 25.4079 29.3122 27.5613 23.2941C29.8094 16.2789 27.4268 9.43062 27.4268 9.43062ZM5.52509 29.9796H4.5635V3.31676H5.52509V29.9796ZM12.299 31.0991C9.7015 30.8382 9.66687 28.6839 9.66687 28.6839L9.65464 2.57112C9.65464 2.57112 11.2274 2.0017 14.5227 2.12088C14.5227 2.12088 17.4839 1.97623 19.4142 5.14216C19.4142 5.14216 21.2793 8.2052 21.4118 13.1884L21.4861 16.2871C21.4861 16.2871 21.6063 22.297 20.1395 25.5872C17.9392 31.7092 12.299 31.0991 12.299 31.0991ZM18.749 29.5833C18.749 29.5833 22.6148 26.8534 22.7421 16.503C22.7655 16.4032 22.8103 14.863 22.7105 13.5877C22.5771 11.8937 22.2868 10.3688 22.2868 10.3688C21.2712 4.36799 18.694 2.87977 18.6136 2.83495C21.863 3.86275 23.5611 9.31144 23.7434 15.0494C23.9461 21.4321 22.2684 28.1724 18.749 29.5833Z"
        fill="#0091AC"
      />
      <path
        d="M59.9998 1.18671V0H45.1633V1.18671C45.1633 1.18671 48.15 1.21727 49.1767 3.14963V14.7529H37.5999V3.15064C37.5999 3.15064 38.154 1.41591 41.6461 1.20301V0.0152793H26.8096V1.20301C26.8096 1.20301 29.7963 1.23255 30.823 3.16592V30.1466C29.7963 32.0798 26.8086 32.1096 26.8086 32.1096V33.2963H41.6461V32.1096C38.1543 31.8957 37.5999 30.1619 37.5999 30.1619V16.9034H49.1767V30.1305C48.15 32.0628 45.1633 32.0924 45.1633 32.0924V33.2801H59.9998V32.0924C56.5088 31.8805 55.9547 30.1467 55.9547 30.1467V3.13343C55.9549 3.13343 56.5089 1.39869 59.9998 1.18671ZM32.9581 30.1344H31.8925V3.31678H32.9581V30.1344ZM51.3504 30.1202H50.2848V3.30242H51.3504V30.1202Z"
        fill="#0091AC"
      />
    </svg>
  );
}
