import React from "react";
import { Link } from "@quintype/components";
import { object, number } from "prop-types";
import Cardimage from "../../atoms/cardimage";
import Headline from "../../atoms/headline";

import "./story-card16.m.css";
function StoryCard16({ story, headerType, headerLevel, lineClamp, type = "a" }) {
  const { slug, headline } = story;
  return (
    <Link href={`/${slug}`}>
      <div styleName="wrapper">
        <div styleName={`card-image-${type}`}>
          <Cardimage
            story={story}
            aspectRatio={[16, 9]}
            imageType="image16x9"
            renderedWidth={720}
            widths={[700]}
            showVideoIcon={true}
          />
        </div>
        <div styleName={`heading-${type}`}>
          <Headline
            text={headline}
            headerType={headerType}
            headerLevel={headerLevel}
            lineClamp={lineClamp}
            story={story}
          />
        </div>
      </div>
    </Link>
  );
}

StoryCard16.defaultProps = {
  headerType: 2,
  headerLevel: 3,
  lineClamp: 3
};

StoryCard16.propTypes = {
  story: object.isRequired,
  headerType: number,
  headerLevel: number,
  lineClamp: number
};

export default StoryCard16;
