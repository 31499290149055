import React from "react";

const YoutubeIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Assets" transform="translate(-136.000000, -232.000000)" fill="#FFFFFF">
        <g id="Group-3-Copy-2" transform="translate(136.000000, 232.000000)">
          <path
            d="M22,0 C23.1045695,-2.02906125e-16 24,0.8954305 24,2 L24,22 C24,23.1045695 23.1045695,24 22,24 L2,24 C0.8954305,24 -7.52907669e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,-6.85272294e-16 2,0 L22,0 Z M12.2793841,6.00095991 L11.7206154,6.00095991 C10.6167489,6.00691138 6.56447926,6.0497619 5.43624,6.35828571 C4.71346667,6.55542857 4.14413333,7.13638095 3.95112,7.87390476 C3.63762,9.06744218 3.60403071,11.4189606 3.60043186,11.9091561 L3.60001599,11.9895198 C3.6,11.9964422 3.6,12 3.6,12 L3.60001599,12.0104802 L3.60043186,12.0908439 C3.60403071,12.5810394 3.63762,14.9325578 3.95112,16.1260952 C4.14413333,16.8638095 4.71346667,17.4445714 5.43624,17.6417143 C6.56447926,17.9502381 10.6167489,17.9930886 11.7206154,17.9990401 L11.9880361,17.9999923 C11.9959463,18 12,18 12,18 L12.0119639,17.9999923 L12.2793841,17.9990401 C13.3832475,17.9930886 17.4354948,17.9502381 18.5635733,17.6417143 C19.2865333,17.4445714 19.85568,16.8638095 20.04888,16.1260952 C20.36238,14.9325578 20.3959693,12.5810394 20.3995681,12.0908439 L20.399984,12.0104802 C20.4,12.0035578 20.4,12 20.4,12 L20.399984,11.9895198 L20.3995681,11.9091561 C20.3959693,11.4189606 20.36238,9.06744218 20.04888,7.87390476 C19.85568,7.13638095 19.2865333,6.55542857 18.5635733,6.35828571 C17.4354948,6.0497619 13.3832475,6.00691138 12.2793841,6.00095991 Z M9.97241379,9 L14.6068966,11.7 L9.97241379,14.4 L9.97241379,9 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default YoutubeIcon;
