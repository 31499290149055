import React from "react";

const TwitterIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_334_1548)">
      <rect x="1" y="2" width="22" height="20" fill="black" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V22C0 23.1046 0.89543 24 2 24H22C23.1046 24 24 23.1046 24 22V2C24 0.895431 23.1046 0 22 0H2ZM10.1955 12.8971L2.85671 3.08449H8.51287L13.324 9.51747L19.2786 3.08449H20.9407L14.0667 10.5106L21.8183 20.8752H16.1622L10.9381 13.8903L4.47246 20.8752H2.81037L10.1955 12.8971ZM7.89939 4.30879H5.30094L16.7753 19.6507H19.3737L7.89939 4.30879Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_334_1548">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TwitterIcon;
