import { Link } from "@quintype/components";
import React from "react";
import "./e-paper-button.m.css";
import { SvgIconHandler } from "../svg-icon-hadler";

function EPaperButton() {
  return (
    <div styleName="wrapper">
      <Link href="https://epaper.deccanherald.com/" styleName="e-paper" target="_blank">
        ePaper <SvgIconHandler type="arrow_outward" width="9.46px" height="9px" />
      </Link>
    </div>
  );
}

export default EPaperButton;
