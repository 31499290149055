import { Link } from "@quintype/components";
import React from "react";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./hamburger-my-account-button.m.css";

function HamburgerMyAccountButton({ onAccountHandler }) {
  return (
    <div styleName="wrapper" onClick={onAccountHandler}>
      <Link styleName="my-account" target="_blank">
        <SvgIconHandler type="my_account_icon" width="16px" height="17.33px" />
        My Account
      </Link>
    </div>
  );
}

export default HamburgerMyAccountButton;
