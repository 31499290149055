import React from "react";
import { string } from "prop-types";

function NavBarSheetIcon({ color = "#000" }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Assets" transform="translate(-328.000000, -40.000000)">
          <g id="feed_black_24dp" transform="translate(328.000000, 40.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M16,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,8 L16,3 Z M19,19 L5,19 L5,5 L15,5 L15,9 L19,9 L19,19 Z M7,17 L17,17 L17,15 L7,15 L7,17 Z M12,7 L7,7 L7,9 L12,9 L12,7 Z M7,13 L17,13 L17,11 L7,11 L7,13 Z"
              id="Shape"
              fill={color}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

NavBarSheetIcon.propTypes = {
  color: string
};

export default NavBarSheetIcon;
