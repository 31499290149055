import React, { useEffect, useState } from "react";
import { string, object } from "prop-types";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import { throttle } from "lodash";

import { AdContainer } from "../../ads";
import "./sticky-banner.m.css";
import { setIsStickyBannerVisible } from "../../store/actions";
import useAdType from "../../helper/useAdType";

const StickyBanner = () => {
  const pageType = useSelector((state) => get(state, ["qt", "pageType"]));
  const [showAd, setShowAd] = useState(false);
  const [isClosed, setClosed] = useState(false);
  const [disableGetScroll, setDisableGetScroll] = useState(false);
  const dispatch = useDispatch();
  const adType = useAdType();

  useEffect(() => {
    if (!disableGetScroll) {
      window.addEventListener("scroll", throttle(getScroll, 250));
    }
    return () => window.removeEventListener("scroll", getScroll);
  }, []);

  useEffect(() => {
    if (showAd && !isClosed) {
      dispatch(setIsStickyBannerVisible(true));
    } else {
      dispatch(setIsStickyBannerVisible(false));
    }
  }, [showAd, isClosed]);

  const getScroll = () => {
    if (typeof window !== "undefined") {
      if (window.scrollY > 700) {
        setShowAd(true);
        setDisableGetScroll(true);
      }
    }
  };

  const adsId = {
    "story-page": "div-gpt-ad-1680157923413-0",
    "home-page": "div-gpt-ad-1680157033747-0",
    "izooto-sticky": "div-gpt-ad-1711972192178-0"
  };

  const ads = {
    "story-page": "DH_Mweb_AT_Sticky",
    "home-page": "DH_Mweb_HP_Sticky",
    "izooto-sticky": "DH_Mweb_AT_Sticky_ym"
  };

  return (
    <div styleName="wrapper">
      <div styleName={`banner ${showAd && !isClosed ? "" : "hide"}`}>
        <div
          styleName="cross-btn"
          onClick={() => {
            setShowAd(false);
            setClosed(true);
          }}
        >
          &#xd7;
        </div>
        <div styleName="ad-wrapper">
          <span styleName="container-text">ADVERTISEMENT</span>
          <div styleName="sticky-ad">
            <AdContainer
              showText={false}
              id={adType === "izooto" ? adsId["izooto-sticky"] : adsId[pageType] || "div-gpt-ad-1680157033747-0"}
              mobile={adType === "izooto" ? ads["izooto-sticky"] : ads[pageType] || "DH_Mweb_HP_Sticky"}
              isExternalAdPath={adType === "izooto" ? true : false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

StickyBanner.propTypes = {
  pageType: string,
  story: object
};

export default StickyBanner;
