import React from "react";

import { LoadingIndicator } from "@quintype/components";

import "./loading-indicator.m.css";

const LoadingIndicatorComponent = () => (
  <LoadingIndicator>
    <div styleName="qt-loading-animation"></div>
  </LoadingIndicator>
);

export default LoadingIndicatorComponent;
