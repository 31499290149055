import { Link } from "@quintype/components";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./login-button.m.css";

function LoginButton() {
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  const [redirectURL, setRedirectUrl] = useState("");
  useEffect(() => {
    setRedirectUrl(window.location.href);
  }, []);
  const signinHandler = () => {
    if (window) {
      window.dataLayer.push({
        event: "SSO_Page_Open"
      });
    }
    window.location.href = `${ssoEndpoint}/auth?redirectUrl=${redirectURL}`;
  };
  return (
    <div styleName="wrapper" onClick={signinHandler}>
      <Link styleName="e-paper-btn" target="_blank">
        Login
      </Link>
    </div>
  );
}

export default LoginButton;
