export const OPEN_HAMBURGER_MENU = "OPEN_HAMBURGER_MENU";
export const OPEN_SEARCHBAR = "OPEN_SEARCHBAR";
export const UPDATE_USER = "UPDATE_USER";
export const ISLOGIN = "ISLOGIN";
export const IS_OPEN_LOGIN_FORM = "IS_OPEN_LOGIN_FORM";
export const UPDATE_COMMENTS_TOKEN = "UPDATE_COMMENTS_TOKEN";
export const NOTIFICATION_DATA = "NOTIFICATION_DATA";
export const OPEN_MODAL = "OPEN_MODAL";
export const ISLOADINGINFO = "ISLOADINGINFO";
export const IS_NEW_NOTIFICATION = "IS_NEW_NOTIFICATION";

// Action to enable/disable to loading indicator
export function setLoading(isLoading) {
  return isLoading ? { type: "QT-PAGE_LOADING" } : { type: "QT-PAGE_FINISHED_LOADING" };
}

export const setMoreStories = (payload) => {
  return {
    type: "MORE_STORIES",
    payload
  };
};

export const setStoryTemplate = (payload) => {
  return {
    type: "STORY_TEMPLATE",
    payload
  };
};

export const setLatestTenStoriesAction = (stories) => {
  return {
    type: "SET_LATEST_TEN_STORIES",
    payload: stories
  };
};

export const setLatestSectionStoriesAction = (stories) => {
  return {
    type: "SET_LATEST_SECTION_STORIES",
    payload: stories
  };
};

export const setMakeSecondaryApiCallsTriggered = (makeSecondaryApiCalls, delay) => {
  return {
    type: "SET_MAKE_SECONDARY_API_CALLS_TRIGGERED",
    makeSecondaryApiCalls: makeSecondaryApiCalls,
    delay: delay
  };
};
export const setIsStickyBannerVisible = (payload) => {
  return {
    type: "SET_IS_STICKY_BANNER_VISIBLE",
    payload
  };
};

export const setUserDetails = (payload) => {
  return {
    type: UPDATE_USER,
    payload
  };
};

export const setCommentsToken = (payload) => {
  return {
    type: UPDATE_COMMENTS_TOKEN,
    payload
  };
};

export const setIsLogin = (payload) => {
  return {
    type: ISLOGIN,
    payload
  };
};
export const setNotificationList = (payload) => {
  return {
    type: NOTIFICATION_DATA,
    payload
  };
};

export const setModalToggle = (payload) => {
  return {
    type: OPEN_MODAL,
    payload
  };
};
export const setIsLoading = (payload) => {
  return {
    type: ISLOADINGINFO,
    payload
  };
};
export const setNewNotification = (payload) => {
  return {
    type: IS_NEW_NOTIFICATION,
    payload
  };
};
