import { Link } from "@quintype/components";
import React from "react";
import "./my-account-button.m.css";
import { SvgIconHandler } from "../svg-icon-hadler";

function MyAccountButton({ onAccountHandler }) {
  return (
    <div styleName="wrapper" onClick={onAccountHandler}>
      <Link styleName="my-account" target="_blank">
        <SvgIconHandler type="my_account_white" width="10.31px" height="11.61px" />
        My Account
      </Link>
    </div>
  );
}

export default MyAccountButton;
