import React from "react";

const WhatsAppIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.5" x="0.5" y="0.5" width="24" height="23" rx="2.5" stroke="#DDDDDD" />
    <path
      d="M6 19L6.98878 15.4049C6.37863 14.3526 6.05803 13.1597 6.05861 11.9364C6.06037 8.11208 9.18731 5 13.0293 5C14.8937 5.00058 16.6439 5.72333 17.9603 7.03467C19.2761 8.346 20.0006 10.089 20 11.9428C19.9982 15.7677 16.8713 18.8798 13.0293 18.8798C11.8629 18.8793 10.7136 18.5882 9.69547 18.0352L6 19ZM9.86662 16.7793C10.8489 17.3597 11.7867 17.7073 13.027 17.7079C16.2201 17.7079 18.8213 15.1214 18.8231 11.9417C18.8242 8.7555 16.2354 6.1725 13.0316 6.17133C9.83614 6.17133 7.23671 8.75783 7.23554 11.937C7.23495 13.2349 7.6171 14.2067 8.2589 15.2235L7.67337 17.3515L9.86662 16.7793ZM16.5407 13.5919C16.4974 13.5196 16.3813 13.4764 16.2066 13.3895C16.0326 13.3026 15.1763 12.8832 15.0162 12.8254C14.8568 12.7677 14.7408 12.7385 14.6241 12.9123C14.5081 13.0856 14.174 13.4764 14.0726 13.5919C13.9712 13.7074 13.8692 13.722 13.6951 13.6351C13.5211 13.5482 12.9596 13.3656 12.2943 12.7747C11.7768 12.315 11.4269 11.7474 11.3255 11.5736C11.2241 11.4003 11.3149 11.3064 11.4017 11.2201C11.4802 11.1425 11.5757 11.0177 11.6631 10.9162C11.7516 10.8158 11.7803 10.7435 11.8389 10.6274C11.8969 10.5119 11.8682 10.4104 11.8242 10.3235C11.7803 10.2372 11.4321 9.38375 11.2874 9.03667C11.1455 8.69892 11.0019 8.74442 10.8953 8.73917L10.5612 8.73333C10.4451 8.73333 10.2564 8.7765 10.097 8.95033C9.93754 9.12417 9.4874 9.543 9.4874 10.3964C9.4874 11.2498 10.1116 12.0741 10.1984 12.1896C10.2857 12.3051 11.4263 14.0563 13.1735 14.807C13.589 14.9855 13.9138 15.0922 14.1664 15.1722C14.5837 15.304 14.9635 15.2853 15.2636 15.241C15.5983 15.1914 16.294 14.8216 16.4393 14.4167C16.5847 14.0113 16.5847 13.6642 16.5407 13.5919Z"
      fill="white"
    />
  </svg>
);

export default WhatsAppIcon;
