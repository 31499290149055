import React from "react";

const TelegramIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.5" x="0.5" y="0.5" width="24" height="23" rx="2.5" stroke="#DDDDDD" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.8746 8.66521C12.7103 9.15967 10.3833 10.1831 6.89368 11.7354C6.32702 11.9655 6.03017 12.1906 6.00315 12.4107C5.95748 12.7826 6.41368 12.9291 7.03491 13.1286C7.11941 13.1557 7.20697 13.1838 7.29673 13.2136C7.90793 13.4164 8.73009 13.6538 9.1575 13.6632C9.54521 13.6717 9.97792 13.5085 10.4557 13.1736C13.7162 10.9264 15.3992 9.79055 15.5049 9.76606C15.5794 9.74879 15.6828 9.72707 15.7527 9.79059C15.8227 9.85411 15.8159 9.9744 15.8084 10.0067C15.7633 10.2034 13.9725 11.9033 13.0458 12.7829C12.7569 13.0572 12.5519 13.2517 12.51 13.2961C12.4162 13.3956 12.3206 13.4898 12.2286 13.5803C11.6608 14.1391 11.235 14.5583 12.2522 15.2427C12.741 15.5716 13.1322 15.8435 13.5224 16.1149C13.9486 16.4112 14.3737 16.7068 14.9236 17.0749C15.0638 17.1686 15.1976 17.266 15.3279 17.3609C15.8239 17.7219 16.2695 18.0463 16.8199 17.9945C17.1398 17.9645 17.4702 17.6574 17.638 16.7415C18.0346 14.5769 18.8141 9.88695 18.9942 7.95433C19.01 7.785 18.9901 7.5683 18.9742 7.47318C18.9583 7.37805 18.9249 7.24252 18.8038 7.14219C18.6604 7.02336 18.439 6.99831 18.34 7.00009C17.8897 7.00818 17.199 7.25343 13.8746 8.66521Z"
      fill="white"
    />
  </svg>
);

export default TelegramIcon;
