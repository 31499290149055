import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SvgIconHandler } from "../../atoms/svg-icon-hadler";
import "./my-account.m.css";
import { Link } from "@quintype/components";
import { setIsLogin, setUserDetails, setVerifyUser } from "../../store/actions";
import { googleLogout } from "@react-oauth/google";
import MyProfile from "./my-profile";
import SavedArticles from "./saved-articles";
import { get } from "lodash";

function MyAccount({ closeModal }) {
  const dispatch = useDispatch();
  const ssoEndpoint = useSelector((state) =>
    get(state, ["qt", "config", "publisher-attributes", "sso_endpoint"], null)
  );
  const [isDropdownCollapsed, setIsDropdownCollapsed] = useState("myprofile");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const userdetails = useSelector((state) => get(state, ["user", "user"], {}));

  useEffect(() => {
    if (window) {
      const url = window.location.href.split("?")[0];
      setRedirectUrl(url);
    }
  }, []);

  useEffect(() => {
    if (userdetails?.userData?.id) {
      setIsLoading(false);
    }
  }, [userdetails?.userData?.id]);

  const onDropdownBtnHandler = (value) => {
    if (value === isDropdownCollapsed) {
      setIsDropdownCollapsed("");
    } else {
      setIsDropdownCollapsed(value);
    }
  };

  const logoutHandler = () => {
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    localStorage.removeItem("commentsToken");
    window.location.search = "";
    googleLogout();
    dispatch(setIsLogin(false));
    closeModal();
  };
  if (isLoading)
    return (
      <div styleName="loading-animation">
        <div styleName="loading-animation__circle"></div>
      </div>
    );

  return (
    <div styleName="my-account-wrapper">
      <div styleName="close-btn-wrapper" onClick={closeModal}>
        <SvgIconHandler type="close_small" height="10" width="10" />
      </div>
      {/* <p styleName="user-mail-id">{email}</p> */}
      <div styleName={`my-account-detail-container ${isDropdownCollapsed === "myprofile" ? "collapsed" : "close"}`}>
        <div
          styleName={`title ${isDropdownCollapsed === "myprofile" ? "title-bg" : ""}`}
          // onClick={() => onDropdownBtnHandler("myprofile")}
        >
          <span styleName="title-text">My Profile</span>
          {/* <div styleName="dropdown-svg-container">
            {isDropdownCollapsed === "myprofile" ? (
              <SvgIconHandler type="black_down_arrow" width="24" height="25" />
            ) : (
              <SvgIconHandler type="black_right_arrow" width="24" height="25" />
            )}
          </div> */}
        </div>
        {isDropdownCollapsed === "myprofile" && <MyProfile />}
      </div>
      {/* <div styleName={`${isDropdownCollapsed === "savedarticles" ? "collapsed" : "close"}`}>
        <div
          styleName={`title ${isDropdownCollapsed === "savedarticles" ? "title-bg" : ""}`}
          onClick={() => onDropdownBtnHandler("savedarticles")}
        >
          <span styleName="title-text">Saved Articles</span>
          <div styleName="dropdown-svg-container">
            {isDropdownCollapsed === "savedarticles" ? (
              <SvgIconHandler type="black_down_arrow" width="24" height="25" />
            ) : (
              <SvgIconHandler type="black_right_arrow" width="24" height="25" />
            )}
          </div>
        </div>
        {isDropdownCollapsed === "savedarticles" && <SavedArticles savedStories={savedStories} />}
      </div> */}
      <button
        styleName="logout-btn"
        onClick={() => {
          logoutHandler();
        }}
      >
        <Link href={`${ssoEndpoint}/auth/logout?redirectUrl=${redirectUrl}`}>
          <SvgIconHandler type="logout" height="18" width="18" /> Log Out
        </Link>
      </button>
    </div>
  );
}

export default MyAccount;
