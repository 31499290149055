import get from "lodash/get";
import axios from "axios";

const API_BASE_URL = "/api/v1";

const axiosInstance = axios.create({
  baseURL: API_BASE_URL
});

export const getSearchPageItems = async (slug, offset, limit) => {
  const { results } = await (await fetch(`/api/v1/search?q=${slug}&offset=${offset}&limit=${limit + 1}`)).json();
  const loadMoreStories = results.stories.map((story) => {
    return { type: "story", story: story };
  });
  return loadMoreStories;
};

export const getStories = async (query, slug, offset, limit) => {
  const { stories } = await (
    await fetch(`/api/v1/stories?${query}=${slug}&offset=${offset}&limit=${limit + 1}`)
  ).json();
  const loadMoreStories = stories.map((story) => {
    return { type: "story", story: story };
  });
  return loadMoreStories;
};

export const getCollectionitems = async (slug, offset, limit) => {
  const { items } = await (
    await fetch(`/api/v1/collections/${slug}?&item-type=story&offset=${offset}&limit=${limit + 1}`)
  ).json();
  return items;
};

export const getAuthorStories = async (id, offset, limit) => {
  const { items } = await (
    await fetch(`/api/v1/authors/${id}/collection?&item-type=story&offset=${offset}&limit=${limit}`)
  ).json();
  return items;
};

export function getAdvancedSearchOfStories(queryParams) {
  const url = "/advanced-search";

  return axiosInstance
    .get(url, {
      params: { ...queryParams }
    })
    .then((response) => response.data);
}

export function getAdvancedSearchAuthor(queryParams) {
  const url = "/authors";

  return axiosInstance
    .get(url, {
      params: { ...queryParams }
    })
    .then((response) => response.data);
}

export const getLinkedStory = (element, story) => {
  const linkedStoryId = get(element, ["metadata", "linked-story", "id"], "");
  const linkedStory = get(story, ["linked-stories", linkedStoryId], {});
  const linkedStorySlug = get(story, ["linked-stories", linkedStoryId, "url"], "");

  return { linkedStory, linkedStorySlug };
};
