import React from "react";

const TwitterIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.5" x="0.5" y="0.5" width="24" height="23" rx="2.5" stroke="#DDDDDD" />
    <path
      d="M17.2176 5.26929H19.4666L14.5532 10.8849L20.3334 18.5266H15.8076L12.2628 13.892L8.20671 18.5266H5.95637L11.2117 12.52L5.66675 5.26929H10.3075L13.5117 9.50551L17.2176 5.26929ZM16.4283 17.1805H17.6745L9.63034 6.54471H8.29306L16.4283 17.1805Z"
      fill="white"
    />
  </svg>
);

export default TwitterIcon;
