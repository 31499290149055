import React from "react";
import { string, object, bool } from "prop-types";
import { Link } from "@quintype/components";

import "./menu-item.m.css";

const MenuItem = ({
  type = "primary",
  item,
  toggleHandler,
  menuStyle = "menu-link",
  changeTextWeight = false,
  changeTextColor = false
}) => {
  return (
    <div styleName={`${type}-menu-link-wrapper`} className="menu-link-wrapper">
      <Link
        styleName={`${menuStyle} ${changeTextColor ? "special-text" : ""} ${
          changeTextWeight ? "special-text-bold" : ""
        }`}
        aria-label="menu-item"
        callback={toggleHandler}
        className={menuStyle}
        menu-title={item.title}
        href={item?.url ? item.url : "/"}
        externalLink={item.isExternalLink && item?.url}
        style={{ color: `${changeTextColor && item?.data?.color ? item?.data?.color : ""}` }}
      >
        {item.title}
      </Link>
    </div>
  );
  // }
};

MenuItem.propTypes = {
  type: string,
  item: object,
  showIcon: bool
};

export { MenuItem };
