import React, { useEffect, useState } from "react";
import EPaperButton from "../../../atoms/e-paper-button";
import HeaderNewsletter from "../../../atoms/header-newsletter";
import MyAccountButton from "../../../atoms/my-account-button";
import HeaderNotification from "../../../atoms/header-notification";
import "./header-right.m.css";
import MyAccount from "../../../molecules/my-account";
import Modal from "../../../atoms/modal";
import SigninButton from "../../../atoms/signin-button";
import { useSelector } from "react-redux";
import { get } from "lodash";
import HamburgerAndSearch from "../../../atoms/hamburger-and-search";

function HeaderRight({ openNotificationModal }) {
  const [showModal, setShowModal] = useState("");
  const isUserLogin = useSelector((state) => get(state, ["isLogin"], false));

  useEffect(() => {
    const isProfileOpen = window.localStorage.getItem("isProfileOpen");

    setShowModal(isProfileOpen);
  }, [showModal]);
  const openModal = () => {
    setShowModal("true");
    localStorage.setItem("isProfileOpen", "true");
  };
  const closeModal = () => {
    setShowModal("false");
    localStorage.setItem("isProfileOpen", "false");
  };

  return (
    <>
      <div styleName="row row1">
        <HeaderNewsletter />
        <EPaperButton />
        <div className="hide-desktop-tab">
          <div onClick={openNotificationModal}>
            <HeaderNotification />
          </div>
        </div>
      </div>
      <div styleName="row row2">
        {!isUserLogin && isUserLogin !== undefined && isUserLogin !== null ? (
          <SigninButton />
        ) : (
          <MyAccountButton onAccountHandler={openModal} />
        )}
        <Modal onClose={closeModal} isOpen={showModal == "true" ? true : false}>
          <MyAccount closeModal={closeModal} />
        </Modal>
        <HamburgerAndSearch />
      </div>
    </>
  );
}

export default HeaderRight;
