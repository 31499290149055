import React, { useState, useEffect, useCallback } from "react";
import { HeaderDesktopAd } from "../../../ads";
import Separator from "../../../atoms/separator";
import throttle from "lodash/throttle";
import get from "lodash/get";
import { useSelector } from "react-redux";

import "./header-ad.m.css";

const DesktopHeaderAd = () => {
  const [url, setURL] = useState("");

  const qtState = useSelector((state) => get(state, ["qt"], {}));
  const storyTemplate = useSelector((state) => get(state, ["storyReducer", "storyTemplate"], []));
  const pageType = get(qtState, ["pageType"], null);
  const pageType2 = get(qtState, ["data", "section"], false);

  useEffect(() => {
    if (location.pathname) {
      setURL(location.pathname);
    }
  }, []);

  const [isActive, setisActive] = useState(false);

  const getScroll = useCallback(
    throttle(() => {
      if (typeof window !== "undefined") {
        if (window.scrollY > 105) {
          setisActive(true);
        }
        if (window.scrollY < 106) {
          setisActive(false);
        }
      }
    }, 250),
    []
  );

  useEffect(() => {
    window.addEventListener("scroll", getScroll);
    return () => {
      window.removeEventListener("scroll", getScroll);
      getScroll.cancel(); // Cancel any pending throttled calls
    };
  }, [getScroll]);

  // Conditional rendering logic moved to the bottom, after all hooks
  if (storyTemplate === "immersive") {
    return null;
  }
  if (!pageType2) {
    if (pageType === "home-page" || pageType === "section-page") return null;
  }
  if (
    pageType === "not-found" ||
    pageType === "static-page" ||
    pageType === "newsletters" ||
    pageType === "news-letters-unsubscribe" ||
    pageType === "news-shots-page" ||
    pageType === "students-contest"
  )
    return null;

  return (
    <div
      styleName={`wrapper ${isActive ? "add-margin" : ""}`}
      className={`hide-mobile ${url.includes("/news-shots") ? "hide-desktop" : ""}`}
    >
      <Separator />
      <HeaderDesktopAd />
      {pageType !== "section-page" && pageType !== "sub-section-page" && pageType !== "home-page" ? (
        <Separator />
      ) : null}
    </div>
  );
};

export default DesktopHeaderAd;
