import React from "react";
import PT from "prop-types";

import "./hamburger-menu.m.css";

export const HamburgerMenu = ({ onMenuToggle, isMegaMenuOpen, isMobileHamburger }) => (
  <button
    aria-label="Hamburger Menu"
    className="hamburger-menu"
    onClick={onMenuToggle}
    styleName={`hamburger ${isMegaMenuOpen ? "is-open" : ""}`}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
          <path d="M4 11.867h16M4 6.451h16M4 17.282h16" />
        </g>
      </g>
    </svg>
  </button>
);

HamburgerMenu.propTypes = {
  onMenuToggle: PT.func,
  isMegaMenuOpen: PT.bool,
  isMobileHamburger: PT.bool
};

export default HamburgerMenu;
