import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./header-notification.m.css";
function HeaderNotification() {
  const [loadIcon, setLoadIcon] = useState(false);
  const getState = useSelector((state) => state);
  const isNewNotification = get(getState, "isNewNotification");

  useEffect(() => {
    setTimeout(() => {
      setLoadIcon(true);
    }, 300);
  }, []);
  if (loadIcon) {
    return (
      <div styleName="bell-icon">
        <SvgIconHandler
          type={`${isNewNotification ? "headerrednotification" : "bellheaderl2"}`}
          width="24px"
          height="24px"
        />
      </div>
    );
  }
  return null;
}

export default HeaderNotification;
