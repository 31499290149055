import React from "react";

const YoutubeIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.5" x="0.5" y="0.5" width="24" height="23" rx="2.5" stroke="#DDDDDD" />
    <path
      d="M19.8605 9.20005C19.8605 9.20005 19.7238 8.23481 19.3027 7.81099C18.7695 7.25317 18.1734 7.25044 17.9 7.21763C15.9422 7.07544 13.0027 7.07544 13.0027 7.07544H12.9973C12.9973 7.07544 10.0578 7.07544 8.1 7.21763C7.82656 7.25044 7.23047 7.25317 6.69727 7.81099C6.27617 8.23481 6.14219 9.20005 6.14219 9.20005C6.14219 9.20005 6 10.3348 6 11.4668V12.5278C6 13.6598 6.13945 14.7946 6.13945 14.7946C6.13945 14.7946 6.27617 15.7598 6.69453 16.1836C7.22773 16.7415 7.92773 16.7223 8.23945 16.7825C9.36055 16.8891 13 16.9219 13 16.9219C13 16.9219 15.9422 16.9165 17.9 16.777C18.1734 16.7442 18.7695 16.7415 19.3027 16.1836C19.7238 15.7598 19.8605 14.7946 19.8605 14.7946C19.8605 14.7946 20 13.6625 20 12.5278V11.4668C20 10.3348 19.8605 9.20005 19.8605 9.20005ZM11.5535 13.8157V9.88091L15.3352 11.8551L11.5535 13.8157Z"
      fill="white"
    />
  </svg>
);

export default YoutubeIcon;
