import React from "react";

const FacebookIcon = () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.5" x="0.5" y="0.5" width="24" height="23" rx="2.5" stroke="#DDDDDD" />
    <path
      d="M16 9.90003H13.6556V8.50003C13.6556 7.77763 13.7114 7.32264 14.6951 7.32264H15.9375V5.09664C15.3329 5.03084 14.725 4.99864 14.1165 5.00004C12.3121 5.00004 10.9952 6.15994 10.9952 8.28933V9.90003H9V12.7L10.9952 12.6993V19H13.6556V12.6979L15.6947 12.6972L16 9.90003Z"
      fill="white"
    />
  </svg>
);

export default FacebookIcon;
