import { get, throttle } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import DesktopHamburgerExpand from "../../molecules/desktop-hamburger-expand";
import Modal from "../modal";
import { SvgIconHandler } from "../svg-icon-hadler";

import HamburgerSearchContent from "../hamburger-search-content";
import { useDispatch, useSelector } from "react-redux";
import { OPEN_SEARCHBAR, setModalToggle } from "../../store/actions";
import "./hamburger-and-search.m.css";

const HamburgerAndSearch = ({ height = "16px", width = "16px" }) => {
  const [searchBox, showSearchBox] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const dispatch = useDispatch();
  const getState = useSelector((state) => state);
  const isModalOpen = get(getState, ["isModalOpen"], false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  const openModal = () => {
    // setShowModal(true);
    dispatch(setModalToggle(true));
    // dispatch({
    //   type: OPEN_SEARCHBAR,
    //   isSearchBarOpen: false
    // });
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const dropdownRef = useRef(null);

  const handleClickOutside = throttle((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      showSearchBox(false);
    }
  }, 250);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <>
      <div styleName="search-wrapper" ref={dropdownRef}>
        <button onClick={openModal} styleName="search-box">
          <SvgIconHandler type="search_icon" height="28px" width="28px" />
        </button>
        {/* {showModal && (
          <Modal onClose={closeModal} isOpen={showModal}> */}
        {isMobile ? (
          <HamburgerSearchContent closeModal={closeModal} />
        ) : (
          <DesktopHamburgerExpand onClose={closeModal} />
        )}
        {/* </Modal>
        )} */}
      </div>
    </>
  );
};

export default HamburgerAndSearch;
