import React, { useEffect, useState } from "react";

function useAdType() {
  const [adType, setAdType] = useState("");

  useEffect(() => {
    try {
      const searchParams = new URLSearchParams(window.location.search);
      const utmSource = searchParams.get("utm_source");
      if (utmSource === "izooto") {
        setAdType("izooto");
      }
    } catch (e) {}
  }, []);
  return adType;
}

export default useAdType;
