import React from "react";
import { formatDistanceToNowStrict, format } from "date-fns";

import "./story-date.m.css";

const StoryDate = ({ story }) => {
  if (!story) return null;
  if (!story["last-published-at"]) return null;

  const lastUpdated = formatDistanceToNowStrict(new Date(story && story["last-published-at"]));
  const lastUpdatedDateAndTime = format(new Date(story && story["last-published-at"]), "dd MMMM yyyy, HH:mm");

  const more_than_24_hours =
    lastUpdated.includes("day") || lastUpdated.includes("month") || lastUpdated.includes("year");

  return (
    <div className="story-date" styleName="wrapper">
      {more_than_24_hours ? `${lastUpdatedDateAndTime} IST` : `${lastUpdated} ago`}
    </div>
  );
};

export default StoryDate;
