import React from "react";
import { object, array, bool, string, number } from "prop-types";
import { connect, useSelector } from "react-redux";
import get from "lodash/get";
import cx from "classnames";
import { ResponsiveHeroImage } from "@quintype/components";
import VideoIcon from "../../atoms/icons/video-icon";
import "./cardImage.m.css";

const CardImage = ({
  story,
  aspectRatio,
  defaultWidth,
  imageType,
  renderedWidth,
  widths,
  eager,
  showAlternateImage,
  delay,
  showVideoIcon,
  showCameraIcon,
  showMetaData
}) => {
  const pageType = useSelector((state) => get(state, ["qt", "pageType"]));
  const wrapperStyleName = cx(
    "qt-image-dynamic",
    { image16x9: imageType === "image16x9" },
    { image4x3: imageType === "image4x3" },
    { image1x1: imageType === "image1x1" },
    { image2x1: imageType === "image2x1" },
    { image3x1: imageType === "image3x1" },
    { image3x4: imageType === "image3x4" },
    { image3x2: imageType === "image3x2" },
    { image2x3: imageType === "image2x3" },
    { image5x4: imageType === "image5x4" },
    { image9x16: imageType === "image9x16" }
  );

  let heroImage = "";
  const storyPage = pageType === "story-page";
  const altHeroImage = get(story?.alternative?.home, ["default", "hero-image", "hero-image-s3-key"], "");

  if (showAlternateImage && !storyPage && altHeroImage) {
    heroImage = altHeroImage;
  } else {
    heroImage = story?.["hero-image-s3-key"] || altHeroImage;
  }

  const imgParams = { auto: ["format", "compress"], fmt: "webp", fit: "max" };

  const isShowVideoIcon =
    (story["story-template"] === "video" || story["story-template"] === "video-playlist") && showVideoIcon;

  return (
    <>
      {heroImage ? (
        <div styleName="card-image-wrapper" className="card-image-wrapper" id={`image-wrapper-${pageType}`}>
          <div styleName="card-image-container">
            <figure
              className={`${wrapperStyleName} qt-figure`}
              styleName={delay || imageType === "image16x9" ? `${wrapperStyleName} hero-image` : `${wrapperStyleName}`}
            >
              <picture className="qt-image">
                {
                  <ResponsiveHeroImage // Desktop WebP
                    story={story}
                    slug={decodeURIComponent(heroImage)}
                    metadata={showMetaData ? story["hero-image-metadata"] : ""}
                    aspectRatio={aspectRatio}
                    defaultWidth={defaultWidth}
                    widths={widths}
                    type="image/webp"
                    imgParams={imgParams}
                    styleName="image"
                    className="image"
                    alt={story.headline || story["hero-image-caption"]}
                  />
                }
              </picture>
              {isShowVideoIcon && (
                <div styleName="video-icon-wrapper">
                  <VideoIcon />
                </div>
              )}
            </figure>
            {story["story-template"] === "photo" && showCameraIcon && (
              <div styleName="camera-icon-wrapper">
                <img src="/icons/imageIcon.png" alt="err" />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div styleName="card-image-wrapper" className="card-image-wrapper" id={`image-wrapper-${pageType}`}>
            <figure className={`${wrapperStyleName} qt-figure`} styleName={`${wrapperStyleName}`}>
              <picture>
                <img
                  id={`fallback-${imageType}`}
                  src="https://images.deccanherald.com/deccanherald-training/2023-06/432d1cbb-92d8-4705-abe7-cce40d752583/DH_cover.svg"
                  alt="fallback"
                  styleName="fallback"
                />
              </picture>
              {isShowVideoIcon && (
                <div styleName="video-icon-wrapper">
                  <VideoIcon />
                </div>
              )}
            </figure>
          </div>
        </>
      )}
    </>
  );
};

CardImage.defaultProps = {
  story: {},
  aspectRatio: [1, 1],
  imageType: "image1x1",
  renderedWidth: 720,
  widths: [250, 480, 764],
  eager: false,
  showAlternateImage: true,
  showMetaData: true,
  showAuthorName: false,
  showHeadline: false,
  showImageCaption: false,
  showImageCaptionType2: false,
  delay: false,
  showCameraIcon: true,
  showVideoIcon: true
};

CardImage.propTypes = {
  story: object,
  aspectRatio: array,
  defaultWidth: number,
  imageType: string,
  renderedWidth: number,
  widths: array,
  eager: bool,
  showAlternateImage: bool,
  showAuthorName: bool,
  showHeadline: bool,
  showImageCaption: bool,
  showImageCaptionType2: bool,
  delay: bool,
  currentPath: string,
  showVideoIcon: bool,
  showCameraIcon: bool,
  showMetaData: bool
};

const mapStateToProps = (state) => ({
  currentPath: get(state, ["qt", "currentPath"], "")
});

export default connect(mapStateToProps, null)(CardImage);
