import React from "react";

const TelegramIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Assets" transform="translate(-184.000000, -232.000000)" fill="#FFFFFF">
        <g id="Group-2-Copy-2" transform="translate(184.000000, 232.000000)">
          <path
            d="M22,0 C23.1045695,-2.02906125e-16 24,0.8954305 24,2 L24,22 C24,23.1045695 23.1045695,24 22,24 L2,24 C0.8954305,24 -7.52907669e-16,23.1045695 0,22 L0,2 C-1.3527075e-16,0.8954305 0.8954305,-6.85272294e-16 2,0 L22,0 Z M19.4613903,4.88080161 C19.4613903,4.88080161 16.113276,6.13147242 12.5194097,7.50587837 L11.821587,7.77313488 C8.09238635,9.20351537 4.31459674,10.6882708 3.90059811,10.9878514 C3.71953115,11.1187687 3.65886438,11.1963493 3.62876433,11.2860519 C3.48339743,11.7175941 3.93629817,11.9091213 3.93629817,11.9091213 L7.73030439,13.1940504 C7.73030439,13.1940504 7.87170462,13.2158699 7.9221047,13.1819284 C8.30774576,12.9284502 10.0823761,11.7640823 11.9680148,10.538061 L12.5086854,10.1868251 C14.6746186,8.78103995 16.8265711,7.40036649 17.0568863,7.3124693 C17.1280531,7.29064975 17.1807865,7.31489369 17.1667865,7.36580597 C17.0802489,7.68178539 15.4755966,9.28639257 13.8275587,10.8721435 L13.3976212,11.2845302 C11.753189,12.8571153 10.1936084,14.2971499 10.1936084,14.2971499 C10.1936084,14.2971499 10.1677084,14.3310914 10.151375,14.3698817 L9.78854109,18.2755812 C9.78854109,18.2755812 9.64014085,19.4732321 10.7930427,18.2755812 C11.6064441,17.4294676 12.3934787,16.7215444 12.787346,16.3797047 C14.0919148,17.315521 15.4958838,18.3507375 16.1018514,18.8913774 C16.4061186,19.1629096 16.6606857,19.2065487 16.8702194,19.1992755 C17.445387,19.177456 17.6059206,18.5204451 17.6059206,18.5204451 C17.6059206,18.5204451 20.288325,7.30277172 20.3779251,5.79964713 C20.3867918,5.65175906 20.3989252,5.55963207 20.4,5.4578075 C20.4014918,5.31719262 20.3888918,5.17657774 20.3669584,5.11111909 C20.3067583,4.92686511 20.2348915,4.87837722 20.122658,4.83231372 C19.877191,4.73776233 19.4613903,4.88080161 19.4613903,4.88080161 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
);

export default TelegramIcon;
