import React from "react";

const FacebookIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path
        d="M24,1.33928571 L24,22.6607143 C24,23.0178574 23.8750003,23.3303571 23.625,23.5982143 C23.3749997,23.8660714 23.0535712,24 22.6607143,24 L16.5535714,24 L16.5535714,14.6785714 L19.6607143,14.6785714 L20.1428571,11.0892857 L16.5535714,11.0892857 L16.5535714,8.78571429 C16.5535714,8.1785717 16.6607143,7.75000027 16.875,7.5 C17.160714,7.17857143 17.6428569,7.01785714 18.3214286,7.01785714 L20.25,7.01785714 L20.25,3.75 C19.5,3.6785717 18.5714288,3.64285714 17.4642857,3.64285714 C16.0714286,3.64285714 14.9464286,4.0535717 14.0892857,4.875 C13.2321429,5.6964283 12.8035714,6.87499973 12.8035714,8.41071429 L12.8035714,11.0892857 L9.69642857,11.0892857 L9.69642857,14.6785714 L12.8035714,14.6785714 L12.8035714,24 L1.33928571,24 C0.946428844,24 0.625000272,23.8660714 0.375,23.5982143 C0.124999728,23.3303571 0,23.0178574 0,22.6607143 L0,1.33928571 C0,0.946428844 0.124999728,0.625000272 0.375,0.375 C0.625000272,0.124999728 0.946428844,0 1.33928571,0 L22.6607143,0 C23.0178574,0 23.3303571,0.124999728 23.5982143,0.375 C23.8660714,0.625000272 24,0.946428844 24,1.33928571 Z"
        id="path-1"
      ></path>
    </defs>
    <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Assets" transform="translate(-40.000000, -232.000000)">
        <g id="Icons/Facebook/Solid" transform="translate(40.000000, 232.000000)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use id="facebook" fill="#FFFFFF" xlinkHref="#path-1"></use>
        </g>
      </g>
    </g>
  </svg>
);

export default FacebookIcon;
