import React from "react";

const VideoIcon = ({ width = "40", height = "40" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <path
        fill="#FFF"
        d="M15.15 14.25v11.5c0 .6.258 1.05.775 1.35.517.3 1.025.283 1.525-.05l9.05-5.8c.467-.3.7-.717.7-1.25s-.233-.95-.7-1.25l-9.05-5.8c-.5-.333-1.008-.35-1.525-.05-.517.3-.775.75-.775 1.35zM20 40c-2.733 0-5.317-.525-7.75-1.575-2.433-1.05-4.558-2.483-6.375-4.3-1.817-1.817-3.25-3.942-4.3-6.375C.525 25.317 0 22.733 0 20c0-2.733.525-5.317 1.575-7.75 1.05-2.433 2.483-4.558 4.3-6.375 1.817-1.817 3.942-3.25 6.375-4.3C14.683.525 17.267 0 20 0c2.733 0 5.317.525 7.75 1.575 2.433 1.05 4.558 2.483 6.375 4.3 1.817 1.817 3.25 3.942 4.3 6.375C39.475 14.683 40 17.267 40 20c0 2.733-.525 5.317-1.575 7.75-1.05 2.433-2.483 4.558-4.3 6.375-1.817 1.817-3.942 3.25-6.375 4.3C25.317 39.475 22.733 40 20 40zm0-3c4.667 0 8.667-1.667 12-5s5-7.333 5-12-1.667-8.667-5-12-7.333-5-12-5-8.667 1.667-12 5-5 7.333-5 12 1.667 8.667 5 12 7.333 5 12 5z"
      />
    </svg>
  );
};

export default VideoIcon;
