import React from "react";
import PT from "prop-types";

export const FooterGooglePlay = ({ height = "45", width = "153" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 40" width={width} height={height}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M124 .5c.963 0 1.837.394 2.471 1.029A3.489 3.489 0 0 1 127.5 4v32c0 .962-.394 1.837-1.029 2.471A3.489 3.489 0 0 1 124 39.5H4a3.489 3.489 0 0 1-2.471-1.029A3.489 3.489 0 0 1 .5 36V4c0-.962.394-1.837 1.029-2.471A3.489 3.489 0 0 1 4 .5z"
        stroke="#A9AAA9"
        fill="#000"
        fillRule="nonzero"
      />
      <path
        d="M9.8 19.6V9.1c0-.6.5-1.1 1-1.1s.7.1 1 .3l19.3 10.6c.4.2.6.5.6.8 0 .3-.2.6-.6.8L11.8 31.1c-.2.1-.5.3-1 .3s-1-.5-1-1.1V19.6z"
        fill="#4285F4"
      />
      <path d="M22.3 19.7 10.6 8h.2c.5 0 .7.1 1 .3L26 16.1l-3.7 3.6z" fill="#34A852" />
      <path d="m25.9 23.2-3.6-3.6 3.7-3.7 5.1 2.8c.4.2.6.5.6.8 0 .3-.2.6-.6.8l-5.2 2.9z" fill="#FABD05" />
      <path d="m10.7 31.2 11.6-11.6 3.6 3.6-14.1 7.7c-.3.2-.5.3-1.1.3.1 0 .1 0 0 0z" fill="#EA4337" />
      <path
        d="M71.1 33.9c-.5-.4-.9-1.1-1.1-1.5l1.6-.7c.1.2.3.5.5.8.4.4 1 .8 1.6.8.6 0 1.3-.3 1.7-.8.3-.5.5-1 .5-1.7v-.6c-1.2 1.5-3.7 1.3-5.1-.3-1.5-1.6-1.5-4.3 0-5.9 1.5-1.5 3.7-1.7 5-.3V23h1.7v7.5c0 1.9-.7 3-1.6 3.7-.6.5-1.5.7-2.3.7-.9-.1-1.8-.4-2.5-1zm41.9.4 1.7-4-3-6.9h1.7l2.1 4.9 2.1-4.9h1.7l-4.6 10.9H113zm-8.1-3.8c-.5-.5-.7-1.2-.7-1.9 0-.6.2-1.2.6-1.6.7-.7 1.7-1 2.8-1 .7 0 1.3.1 1.8.4 0-1.2-1-1.7-1.8-1.7-.7 0-1.4.4-1.7 1.1l-1.5-.6c.3-.7 1.1-2 3.1-2 1 0 2 .3 2.6 1 .6.7.8 1.5.8 2.6V31h-1.7v-.7c-.2.3-.6.5-.9.7-.4.2-.9.3-1.4.3-.6-.1-1.5-.3-2-.8zm-53.3-3.6c0-2 1.5-4.2 4.2-4.2 2.6 0 4.2 2.2 4.2 4.2s-1.5 4.2-4.2 4.2-4.2-2.2-4.2-4.2zm9 0c0-2 1.5-4.2 4.2-4.2 2.6 0 4.2 2.2 4.2 4.2s-1.5 4.2-4.2 4.2c-2.6 0-4.2-2.2-4.2-4.2zm-20.3 2.2c-2.5-2.5-2.4-6.6.1-9.2 1.3-1.3 2.9-1.9 4.6-1.9 1.6 0 3.2.6 4.4 1.8l-1.2 1.3c-1.8-1.8-4.7-1.7-6.4.1-1.8 1.9-1.8 4.8 0 6.7 1.8 1.9 4.8 2 6.6.1.6-.6.8-1.4.9-2.2h-4.2V24H51c.1.4.1.9.1 1.4 0 1.5-.6 3-1.6 4-1.1 1.1-2.7 1.7-4.3 1.7-1.8-.1-3.6-.7-4.9-2zm42.3.7c-1.5-1.6-1.5-4.3 0-6 1.5-1.6 4-1.6 5.4 0 .5.5.8 1.2 1.1 1.9L83.6 28c.3.7 1 1.3 2 1.3.9 0 1.5-.3 2.1-1.2l1.5 1c-.2.2-.4.4-.5.6-1.7 1.7-4.6 1.7-6.1.1zM93 31V19.9h3.6c2.1 0 3.8 1.5 3.8 3.3 0 1.8-1.5 3.3-3.4 3.3h-2.2v4.4H93v.1zm8.4 0V19.9h1.7V31h-1.7zm-22.6-.2V18.4h1.8v12.4h-1.8zm30.5-3.1c-.4-.3-1-.4-1.6-.4-1.2 0-1.9.6-1.9 1.3 0 .7.7 1.1 1.4 1.1 1 0 2.1-.8 2.1-2zm-51.2-.8c0-1.2-.8-2.5-2.3-2.5-1.5 0-2.3 1.3-2.3 2.5s.8 2.5 2.3 2.5c1.4 0 2.3-1.3 2.3-2.5zm9 0c0-1.2-.8-2.5-2.3-2.5-1.5 0-2.3 1.3-2.3 2.5s.8 2.5 2.3 2.5c1.5 0 2.3-1.3 2.3-2.5zm8.9.7c0-.1 0-.2.1-.3v-.9c0-.1-.1-.3-.1-.4-.3-1-1.2-1.7-2.1-1.7-1.2 0-2.2 1.2-2.2 2.5 0 1.4 1 2.5 2.3 2.5.8.1 1.6-.6 2-1.7zm7.3-.9 3.7-1.6c-.4-.7-1-.9-1.5-.9-1.5.1-2.4 1.7-2.2 2.5zm15.3-3.4c0-1-.8-1.7-1.9-1.7h-2v3.5h2.1c1 0 1.8-.8 1.8-1.8z"
        fill="#FFF"
      />
      <path
        fill="#FFF"
        d="M114.3 32.3h-.1v-.1h.1-.1v.1zM114.3 32.3v-.1.1zM114.3 32.2v.1-.1zM114.3 32.3v-.1.1zM114.4 32.2v.1zM114.4 32.3v-.1.1zM114.4 32.3c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM114.5 32.3v-.1.1-.1.1-.1zM114.5 32.3c0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0 0-.1 0-.1 0 0zM114.6 32.2h-.1.1v.1-.1zM114.6 32.3v-.1.1z"
      />
      <path
        d="M38.9 10.1c0-1.8 1.3-2.9 2.9-2.9 1.1 0 1.8.5 2.3 1.2l-.8.5c-.3-.4-.8-.7-1.5-.7-1.1 0-1.9.8-1.9 2s.8 2 1.9 2c.6 0 1.1-.3 1.3-.5v-.9h-1.6v-.9h2.7V12c-.5.6-1.3 1-2.3 1-1.7 0-3-1.2-3-2.9zM45.4 12.9V7.3h3.8v.9h-2.9v1.4h2.8v.9h-2.8v1.6h2.9v.9zM52 12.9V8.2h-1.7v-.9h4.4v.9H53v4.7zM58.1 12.9V7.3h1v5.6h-1zM61.8 12.9V8.2h-1.7v-.9h4.4v.9h-1.7v4.7zM67.7 10.1c0-1.7 1.2-2.9 2.9-2.9s2.9 1.2 2.9 2.9-1.2 2.9-2.9 2.9-2.9-1.3-2.9-2.9zm4.7 0c0-1.2-.7-2-1.9-2-1.1 0-1.9.9-1.9 2 0 1.2.7 2 1.9 2s1.9-.9 1.9-2zM78.5 12.9l-2.9-4v4h-1V7.3h1l2.9 3.9V7.3h1v5.6z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

FooterGooglePlay.propTypes = {
  onMenuToggle: PT.func,
  isMegaMenuOpen: PT.bool
};
export default FooterGooglePlay;
