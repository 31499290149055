import React from "react";

function MenuRight() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z" />
        <path stroke="#0091AC" strokeWidth="2" d="M15.292 12.5 9 17.044V7.956l6.292 4.544Z" />
      </g>
    </svg>
  );
}

export default MenuRight;
