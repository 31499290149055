import { Link } from "@quintype/components";
import React from "react";
import Cardimage from "../../atoms/cardimage";
import Headline from "../../atoms/headline";

import "./story-card23.m.css";

const StoryCard23 = ({ story }) => {
  const { slug, headline } = story;
  return (
    <Link href={`/${slug}`}>
      <div styleName="wrapper">
        <div styleName="heading">
          <Headline text={headline} headerType={6} headerLevel={1} story={story} />
        </div>
        <Cardimage
          story={story}
          aspectRatio={[2, 1]}
          imageType="image2x1"
          renderedWidth={720}
          widths={[700]}
          showVideoIcon={true}
        />
      </div>
    </Link>
  );
};

export default StoryCard23;
