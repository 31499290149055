import React from "react";
import { string } from "prop-types";

function NavBarHomeIcon({ color = "#000" }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Assets" transform="translate(-280.000000, -40.000000)">
          <g id="home_black_24dp" transform="translate(280.000000, 40.000000)">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path
              d="M12,5.69 L17,10.19 L17,18 L15,18 L15,12 L9,12 L9,18 L7,18 L7,10.19 L12,5.69 M12,3 L2,12 L5,12 L5,20 L11,20 L11,14 L13,14 L13,20 L19,20 L19,12 L22,12 L12,3 Z"
              id="Shape"
              fill={color}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

NavBarHomeIcon.propTypes = {
  color: string
};

export default NavBarHomeIcon;
