export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  SUB_SECTION_PAGE: "sub-section-page",
  COLLECTION_PAGE: "collection-page",
  TAG_PAGE: "tag-page",
  CARTOON_PAGE: "cartoon-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  AUTHORS_PAGE: "authors-page",
  AUTHOR_PAGE: "author-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  CATALOG_PAGE: "catalog-page",
  STORY_PREVIEW: "story-preview",
  HOME_PREVIEW: "home-preview",
  STATIC_PAGE: "static-page",
  RESET_PASSWORD_PAGE: "reset-password-page",
  PROFILE_PAGE: "profile-page",
  USER_LOGIN: "user-login",
  VISUAL_STORY: "visual-story",
  HOROSCOPE_PAGE: "horoscope-page",
  INSTAGRAM_PAGE: "instagram-page",
  NEWS_SHOTS_PAGE: "news-shots-page",
  NEWSLETTERS_PAGE: "newsletters",
  NEWSLETTERS_UNSUBSCRIBE_PAGE: "news-letters-unsubscribe",
  BRAND_SPOT_PAGE: "brandspot-page",
  STUDENTS_CONTEST: "students-contest"
});
export const TAG_PAGE_URL_PREFIX = "/tags/";
export const storyFields =
  "headline,id,subheadline,slug,seo,url,hero-image-s3-key,hero-image-caption,hero-image-metadata,first-published-at,last-published-at,alternative,published-at,authors,author-name,author-id,sections,story-template,metadata,tags";
