import React, { useEffect, useState } from "react";
import { Link } from "@quintype/components";
import { format } from "date-fns";
import CloseIcon from "../../atoms/icons/close";
import "./notification-box.m.css";
import { useSelector } from "react-redux";
import get from "lodash/get";

function NotificationBox({ closeBtnHandler }) {
  const notificationList = useSelector((state) => get(state, "notificationList"));
  const isLoading = useSelector((state) => get(state, "isLoading"));

  return (
    <>
      <div styleName="triangle"></div>
      <div styleName="stories-wrapper">
        <div styleName="stories-header">
          <span styleName="header-title">Notifications</span>
          <div styleName="close-stories" onClick={closeBtnHandler}>
            <CloseIcon fill="#000" />
          </div>
        </div>
        <div styleName="story-cards-wrapper">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            notificationList?.length &&
            notificationList.map((story, index) => {
              return (
                <div key={index} styleName="story-card">
                  <Link href={story?.landingUrl}>
                    <span styleName="headline">{story?.title}</span>
                  </Link>
                  <div styleName="timestamp">
                    <div styleName="date">
                      {story?.createdAt && format(Number(story?.createdAt), "dd MMMM yyyy, H:mm")} IST
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

export default NotificationBox;
