import { Link } from "@quintype/components";
import React, { useEffect, useState } from "react";
import { SvgIconHandler } from "../svg-icon-hadler";
import "./subscribe-to-newsletter.m.css";

function SubscribeToNewsletter() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    if (window && window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);
  if (isMobile) {
    return (
      <div styleName="wrapper">
        <Link target="_blank" href="https://www.deccanherald.com/newsletters" styleName="newsletter-link">
          Subscribe to Newsletters <SvgIconHandler type="newsletter_black" />
        </Link>
      </div>
    );
  } else {
    return (
      <div styleName="wrapper">
        <Link target="_blank" href="https://www.deccanherald.com/newsletters" styleName="newsletter-link">
          Subscribe to Newsletters
        </Link>
      </div>
    );
  }
}

export default SubscribeToNewsletter;
