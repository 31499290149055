import React from "react";
import { string } from "prop-types";

function NavBarCarousalIcon({ color = "#000" }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Assets" transform="translate(-184.000000, -40.000000)">
          <g id="view_carousel_black_24dp" transform="translate(184.000000, 40.000000)">
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <path
              d="M2,7 L6,7 L6,17 L2,17 L2,7 Z M7,19 L17,19 L17,5 L7,5 L7,19 Z M9,7 L15,7 L15,17 L9,17 L9,7 Z M18,7 L22,7 L22,17 L18,17 L18,7 Z"
              id="Shape"
              fill={color}
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

NavBarCarousalIcon.propTypes = {
  color: string
};

export default NavBarCarousalIcon;
